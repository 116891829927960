/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    field: "order_no",
    label: "订单编号",
    type: "input",
    iw: 160,
    props: {
      placeholder: "请输入订单编号"
    }
  },
  {
    field: "station_name",
    label: "加油站名称",
    type: "input",
    iw: 160,
    props: {
      placeholder: "请输入加油站名称"
    }
  },
  {
    field: "sp_code",
    label: "服务商",
    type: "select",
    defaultValue: null,
    iw: 160,
    props: {
      placeholder: "请输入服务商"
    },
    options: []
  },
  {
    field: ["start_time", "end_time"],
    iw: 324,
    label: "创建时间",
    type: "dateRange",
    props: {
      placeholder: ["开始时间", "结束时间"],
      showTime: true,
      format: "YYYY/MM/DD"
    }
  },
  {
    field: "driver_name",
    label: "司机姓名",
    type: "input",
    iw: 160,
    props: {
      placeholder: "请输入司机姓名"
    }
  },
  {
    field: "driver_mobile",
    label: "手机号码",
    type: "input",
    iw: 160,
    props: {
      placeholder: "请输入手机号码"
    }
  }
];
